.button-container {
    display: flex;
    gap: 10px;
    width: auto;
    max-width: 200px;
    min-width: 50px;
    justify-content: flex-start;
}

.button-container.absolute-positioning {
    position: absolute;
    left: 5px;
    top: 5vh;
}

.common-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    z-index: 10;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.common-button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

.common-button:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
    opacity: 0.6;
}

.common-button.back-button {
    top: 10px;
    left: 10px;
}

.common-button.resign-button {
    top: 10px;
    left: 150px;
}

.text-container {
    max-width: 800px;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    overflow-wrap: break-word;
    word-wrap: break-word;
    margin: 0 auto;
}

.text-container hr {
    margin-top: 30px;
    margin-bottom: 30px;
}

.text-container section p {
    margin: 0;
    padding: 5px 0;
}

.text-container li {
    text-align: left;
}

.email-link {
    margin: 0;
    font-weight: 900;
}

.footer-container {
    background-color: #333;
    padding: 20px 0;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    height: 15vh;
    flex-shrink: 0;
}

.footer-container p {
    color: #d4d4d4;
    margin: 10px 0 0;
}

.header-container {
    background-color: #333;
    padding: 5px 0;
    width: 100%;
    box-sizing: border-box;
    height: 5vh;
    flex-shrink: 0;
}

.navigation-container ul {
    display: flex;
    justify-content: center;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.navigation-container ul li {
    margin: 0 10px;
}

.navigation-container ul li a {
    color: #d4d4d4;
    text-decoration: none;
    font-size: 1.2em;
}

.navigation-container ul li a:hover {
    color: #3794ff;
}

@media (max-width: 600px) {
    .navigation-container ul li a {
        font-size: 0.9em;
    }

    .text-container {
        max-width: 100%;
        padding: 10px;
        word-wrap: break-word;
        overflow-x: hidden;
    }

    .text-container h1,
    .text-container h2 {
        text-align: center;
    }

    .text-container p,
    .text-container ul {
        text-align: left;
    }
}