.game-list-container {
    padding: 20px;
    max-width: 800px;
    margin: auto;
}

.game-list-container h2 {
    text-align: center;
    margin-bottom: 20px;
    display: inline-flex;
    align-items: center;
}

.game-list {
    list-style: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
}

.game-item {
    cursor: pointer;
}

.game-card {
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    transition: transform 0.2s, box-shadow 0.2s;
}

.game-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.game-card.inactive {
    opacity: 0.5;
    background-color: #e0e0e0;
}

.game-card h3 {
    margin: 0;
    font-size: 1.2em;
    color: #343a40;
}

.heading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.plus-icon {
    font-size: 24px;
    margin-left: 15px;
    cursor: pointer;
    border: 2px solid #6582ed;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    transition: background-color 0.3s, color 0.3s;
}

.plus-icon:hover {
    background-color: #6582ed;
    color: white;
}