.awaiting-message {
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 15px 25px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    font-size: 20px;
    border-radius: 5px;
    z-index: 1000;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.awaiting-message.show {
    opacity: 1;
}