.portfolio-item {
    cursor: pointer;
    margin-bottom: 20px;
}

.portfolio-card {
    background-color: #2c2c2c;
    border: 1px solid #3a3a3a;
    border-radius: 8px;
    padding: 20px;
    text-align: left;
    transition: transform 0.2s, box-shadow 0.2s;
    max-width: 800px;
    margin: 0 auto;
}

.portfolio-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.portfolio-image {
    width: 150px;
    height: auto;
    border-radius: 8px;
    margin-right: 20px;
    float: left;
    margin-bottom: 10px;
}

.portfolio-content {
    color: #d4d4d4;
}

.portfolio-content h3 {
    color: #d4d4d4;
    margin: 0;
    font-size: 1.5em;
}

.portfolio-content p {
    color: #bdbdbd;
    margin: 10px 0;
    font-size: 1em;
}

.portfolio-technologies {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.portfolio-technologies li {
    display: inline-block;
    background-color: #3a3a3a;
    padding: 5px 10px;
    border-radius: 5px;
    margin: 5px;
    color: #d4d4d4;
}

/* Media Queries */
@media (max-width: 600px) {
    .portfolio-card {
        display: block;
        text-align: center;
    }

    .portfolio-image {
        float: none;
        display: block;
        margin: 0 auto 10px auto;
    }

    .portfolio-content {
        text-align: center;
    }
}