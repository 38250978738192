.drawing-with-ft-container {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
}

.canvas-and-controls-wrapper {
    position: relative;
    width: 100%;
    height: 78vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.controls {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 10;
    padding: 20px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    pointer-events: all;
}

.file-upload {
    margin-bottom: 20px;
}

.custom-file-upload {
    display: inline-block;
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.custom-file-upload:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

input[type="file"] {
    display: none;
}

.sliders {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.slider {
    margin: 10px 0;
}

.slider-label {
    font-size: 14px;
    color: #333;
}

.file-upload-canvas-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

canvas {
    width: 100%;
    height: 100%;
    display: block;
    background-color: #f0f0f0;
}

@media (max-width: 600px) {
    .text-container {
        padding: 10px;
        text-align: left;
    }

    .controls {
        position: absolute;
        top: 10px;
        left: 10px;
        padding: 15px;
    }

    .canvas-and-controls-wrapper {
        height: 70vh;
    }
}