html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
}

body {
    display: flex;
    flex-direction: column;
}

.home-main {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0;
}

.home-canvas-wrapper {
    width: 100%;
    height: 78vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.home-canvas {
    width: 100%;
    height: 100%;
    background-color: #252526;
    border: 1px solid #3c3c3c;
    position: relative;
}