.game-create-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

label {
    display: block;
    margin-bottom: 10px;
    font-size: 16px;
    text-align: center;
}

select {
    display: block;
    width: 200px;
    padding: 10px;
    margin: 5px auto 20px auto;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
}